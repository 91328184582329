import React, { useState, useEffect } from 'react';
import Header from '../../components/header';
import Tarjeta from './tarjeta.js';

const Jurat = () => {

    const [loaded, setLoaded] = useState(false);

    const jurat = [
        ["Sr Ramon Agenjo", "President del Jurat, Conseller de Damm", "2023/RamonAgenjo.jpg"],
        ["Sr. Joan Soteras", "President de la Federació Catalana de Futbol", "2023/Soteras.JPG"],
        ["Sr. Berni Álvarez", "Conseller d'Esports de la Generalitat de Catalunya", "2024/BerniAlvarez2.png"],
        ["Sr. Abel Garcia", "Secretati General de l'Esport i de l'Activitat Física", "2024/AbelGarcia2.png"],
        ["Sr. David Escudé", "President delegat de l'Àrea d’Esports i Activitat Física de la Diputació de Barcelona i Regidor d'Esports de l'Ajuntament de Barcelona", "2023/DavidEscude.jpg"],
        ["Sr. Gerard Esteva", "President de la UFEC", "2016/gerard-esteva.jpg"],
        ["Sr. Joan Laporta", "President del FC Barcelona", "2021/Joan Laporta.jpg"],
        ["Sr. Delfí Geli", "President del Girona FC", "2022/Delfi Geli.png"],
        ["Sr. Chen Yansheng", "President del RCD Espanyol", "2016/Chen-Yansheng.jpg"],
        ["Sr. Vicenç Garcia", "President de l'Industrias Santa Coloma", "2016/vicenc-garcia.jpg"],
        ["Sr. Jorge Villavecchia", "Director General del Grup Damm", "2017/jorge-villavecchia.jpg"],
        ["Sra. Silvia Agenjo", "Consellera de Cacaolat", "2023/SAgenjo.jpg"],
        ["Sra. Isabel Negra", "Responsable de màrqueting d'Estrella Damm", "2023/IsabelNegra.jpg"],
        ['Sr. Alfredo Bustillo', 'Director de Patrocinis, Marca i Imatge Corporativa de CaixaBank', '2016/alfred-bustillo.jpg'],
        ["Sra. Montse Altimira", "Directiva responsable de l'àrea econòmica de la Federació Catalana de Futbol", "2023/MontseAltimira.JPG"],
        ["Sra. Claudia Pons", "Representant de la Lliga Catalana de Futbol Sala de la Federació Catalana de Futbol", "2023/claudiapons.jpg"],
        ["Sra. Laura Ràfols", "Directiva responsable de la Comissió de Futbol Femení de la Federació Catalana de Futbol", "2023/LauraRafols2.png"],
        ["Sra. Dolors Ribalta", "Representant del Comitè Tècnic d'Entrenadors de la Federació Catalana de Futbol", "2023/DolorsRibalta.jpg"],
        ["Sra. Anna Zardaín", "Representant del Comitè Tècnic d'Àrbitres de la Federació Catalana de Futbol", "2023/AnnaZardain.jpg"],
        ["Sr. Gerard López", "Seleccionador de la selecció catalana absoluta masculina", "2016/gerard-lopez.jpg"],
        ["Sr. Xavi Llorens", "Seleccionador de la selecció catalana absoluta femenina", "2021/xavi llorens.jpg"],
        ["Sr. Santi Gea", "Seleccionador de la selecció catalana absoluta masculina de futbol sala", "2017/santi-gea.png"],
        ["Sr. Jordi Barrero", "Seleccionador de la selecció catalana absoluta femenina de futbol sala", "2024/JordiBarrero.JPG"],
        ["Sr. Francisco Ávila", "Cap d'Esports d'EFE", "2017/francisco-avila.jpg"],
        ["Sr. Dani Barcon", "Cap d'Esports de TV3", "2021/dani barcon.jpg"],
        ["Sr. Ramon Besa", "Cap d'Esports d'El País", "2016/ramon-besa.jpg"],
        ["Sr. Francisco Cabezas", "Cap d'Esports d'El Periódico", "2023/FcoCabezas.jpg"],
        ["Sr. Xavi Campos", "Cap d'Esports de Catalunya Ràdio", "2016/Xavi-Campos.jpg"],
        ["Sr. Joan Lluís Garcia", "Cap d'Esports de RAC1", "2022/JL Garcia.jpg"],
        ["Sr. Santi Giménez", "Cap d'Esports del diari AS", "2017/santi-gimenez.jpg"],
        ["Sr. Albert Hernández", "Cap d'Esports de RTVE", "2022/Albert Hernandez.png"],
        ["Sr. Òscar Herreros", "Cap d'Esports de La Xarxa", "2021/oscar herreros.jpg"],
        ["Sr. David Ibáñez", "Responsable d'esports de Mediaset a Catalunya", "2022/David Ibanez.jpg"],
        ["Sr. Jordi Marti", "Cap d'Esports de la Cadena SER BCN", "2021/jordi marti.jpg"],
        ["Sr. Alfredo Martínez", "Cap d'Esports d'Onda Cero", "2017/afredo-martinez.jpg"],
        ["Sr. Xevi Masachs", "Director de L'Esportiu", "2022/Xevi Masachs.jpg"],
        ["Sr. Jordi Montalbo", "Cap d'Esports de BTV", "2021/jordi montalbo.jpg"],
        ["Sr. Manuel Olivan", "Director Radio Marca Barcelona", "2022/JM Olivan.jpg"],
        ["Sr. Manolo Oliveros", "Cap d'Esports de la Cope", "2017/manolo-oliveros.jpg"],
        ["Sr. Joan Josep Pallàs", "Cap d'Esports de la Vanguardia", "2016/JOAN-JOSEP-PALLAS.jpg"],
        ["Sr. Luis Fernando Rojo", "Cap d'Esports del diari Marca", "2017/lf-rojo.jpg"],
        ["Sr. Josep Soldado", "Responsable d'esports de La Sexta/Antena 3 a Catalunya", "2018/josep soldado.jpg"],
        ["Sr. Joan Vehils", "Director de l'Sport", "2023/JoanVehils.jpg"]
    ];

    useEffect(() => {
        // Establecer un tiempo de espera antes de marcar la imagen como cargada
        const timeout = setTimeout(() => {
          setLoaded(true);
        }, 200); // Puedes ajustar el tiempo según tus necesidades
    
        // Limpiar el timeout al desmontar el componente para evitar memory leaks
        return () => clearTimeout(timeout);
      }, []);
    
    return (
        <React.Fragment>
            <div className='text-white flex flex-col items-center h-auto'>
                <Header value='Jurat'/>
                <div className={`sm:w-3/5 w-full sm:px-0 px-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-6 my-16 ${loaded ? 'opacity-100 transition-opacity duration-1000' : 'opacity-0'}`}>
                {
                    jurat.map((persona) => (
                        <Tarjeta persona={persona}/>
                    ))
                }
                </div>
            </div>
        </React.Fragment>
    );
}

export default Jurat;