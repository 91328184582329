import React, { useEffect, useState } from 'react';
import Header from '../../components/header';

const Gala = () => {

    const [gala, setGala] = useState(null);
    const [isActive, setIsActive] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const gales = [
        ['gala1', '1a GALA', 'gala1.png'],
        ['gala2', '2a GALA', 'gala2.jpg'],
        ['gala3', '3a GALA', 'gala3.png'],
        ['gala4', '4a GALA', 'gala4.png'],
        ['gala5', '5a GALA', 'gala5.png'],
        ['gala6', '6a GALA', 'gala6.png'],
        ['gala7', '7a GALA', 'gala7.png'],
        ['gala8', '8a GALA', 'gala8.png'],
        ['gala9', '9a GALA', 'gala9.png'],
        ['gala10', '10a GALA', 'gala10.jpg'],
        ['gala11', '11a GALA', 'gala11.jpg'],
    ];

    const handleGala =(gala) => {
        setGala(gala);
        setIsActive(gala[1]);
    } 

    const cargarImagen = require.context("../../assets/img/gales/", true);

    useEffect(() => {
        setIsActive(gales[10][1]);
        setGala(gales[10]);
    }, []);

    useEffect(() => {
        // Establecer un tiempo de espera antes de marcar la imagen como cargada
        const timeout = setTimeout(() => {
          setLoaded(true);
        }, 200); // Puedes ajustar el tiempo según tus necesidades
    
        // Limpiar el timeout al desmontar el componente para evitar memory leaks
        return () => clearTimeout(timeout);
      }, []);

    return (
        <React.Fragment>
            <div className='text-white flex flex-col items-center'>
                <Header value='La Gala'/>
                <div className={`sm:w-3/5 w-full mt-14 mb-6 flex flex-col items-center justify-center bg-customBlue shadow-2xl shadow-customBlue ${loaded ? 'opacity-100 transition-opacity duration-1000' : 'opacity-0'}`}>
                    <ul className='flex flex-wrap justify-center'>
                    {
                        gales.map((gala) => (
                            <li key={gala[0]} className={isActive === gala[1] ? 'custom-link custom-link-active my-1 mx-2' : 'custom-link my-1 mx-2'} onClick={() => handleGala(gala)}>{gala[1]}</li>
                        ))
                    }                 
                    </ul>
                    {
                        (gala) && 
                        <img className='w-full mt-4' src={cargarImagen(`./${gala[2]}`)} alt={gala[1]}/>
                    }
                    
                </div>
            </div>
            
        </React.Fragment>
    );
}

export default Gala;