import React from 'react';

const Boton = ({value}) => {

    return (
        <React.Fragment>
            <button className='h-[40px] w-50 mb-5 rounded text-customBlue hover:before:bg-darkGold relative h-[50px] w-40 overflow-hidden  bg-gold px-3 text-customBlue font-bold uppercase shadow-2xl transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-darkGold before:transition-all before:duration-500 hover:shadow-darkGold hover:before:left-0 hover:before:w-full'>
                <a href='https://www.fcf.cat/noticies/gala-estrelles' target='_blank' className="relative z-10">{value}</a>
            </button>
        </React.Fragment>
    );
}

export default Boton;