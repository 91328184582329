import React from 'react';
import Header from '../../components/header';
import Caratula from './components/caratula';

const Fotos = () => {
    
    const gales = [
        ['1', '1a Gala Estrelles', 'gala1/foto01.jpg', 'gala1', 'gala1/foto02.jpg', 'gala1/foto03.jpg'],
        ['2', '2a Gala Estrelles', 'gala2/foto01.jpg', 'gala2', 'gala2/foto02.jpg', 'gala2/foto03.jpg',],
        ['3', '3a Gala Estrelles', 'gala3/foto01.jpg', 'gala3', 'gala3/foto02.jpg', 'gala3/foto03.jpg',],
        ['4', '4a Gala Estrelles', 'gala4/foto01.jpg', 'gala4', 'gala4/foto02.jpg', 'gala4/foto03.jpg',],
        ['5', '5a Gala Estrelles', 'gala5/foto01.jpg', 'gala5', 'gala5/foto02.jpg', 'gala5/foto03.jpg',],
        ['6', '6a Gala Estrelles', 'gala6/foto40.jpg', 'gala6', 'gala6/foto02.jpg', 'gala6/foto03.jpg',],
        ['7', '7a Gala Estrelles', 'gala7/foto01.jpg', 'gala7', 'gala7/foto02.jpg', 'gala7/foto03.jpg',],
        ['8', '8a Gala Estrelles', 'gala8/foto16.jpg', 'gala8', 'gala8/foto02.jpg', 'gala8/foto03.jpg',],
        ['9', '9a Gala Estrelles', 'gala9/gala9_1.jpg', 'gala9', 'gala9/gala9_2.jpg', 'gala9/gala9_3.jpg',],
        ['10', '10a Gala Estrelles', 'gala10/gala10_5.jpg', 'gala10', 'gala10/gala10_2.jpg', 'gala10/gala10_3.jpg',],
        ['11', '11a Gala Estrelles', 'gala11/gala11_10.JPG', 'gala11', 'gala11/gala11_1.JPG', 'gala11/gala11_2.JPG',],
    ]

    return (
        <React.Fragment>
            <div className='text-white flex flex-col items-center'>
                <Header value="Fotos"/>
                <div className='sm:w-3/5 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-6 mt-16 mb-20'>
                {
                    gales.map((gala, index) => (
                        <div key={index} className='flex justify-center'>
                            <Caratula key={gala[0]} gala={gala}/>
                        </div>
                    ))
                }
                </div>
            </div>
        </React.Fragment>
    );
}

export default Fotos;