import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Noticia from './noticia';
import Boton from '../../components/boton';
import Loading from '../../components/loading';

const Noticies = () => {

    //-----------------Gestión datos--------------------
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //Inicializamos el método
        const requestOptions = {
            method: 'GET',
        };

        //Obtenemos todas las noticias
        fetch('https://apidev.fcf.cat/news/news-category/1/3000318/10', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.result) {
                    setData(data.result.NOTICIAS);
                    setLoading(false);
                } else {
                    // Si no se encuentra la propiedad 'NOTICIAS', puedes manejarlo según tu lógica
                    console.error('No se encontraron noticias en la respuesta API.');
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('Hubo un problema con la petición Fetch:' + error.message);
                setLoading(false);
            });

        }, []);

    useEffect(() => {
        // Establecer un tiempo de espera antes de marcar la imagen como cargada
        const timeout = setTimeout(() => {
          setLoaded(true);
        }, 200); // Puedes ajustar el tiempo según tus necesidades
    
        // Limpiar el timeout al desmontar el componente para evitar memory leaks
        return () => clearTimeout(timeout);
      }, []);

      return (
        (loading) ? <Loading/> :
        <React.Fragment>
          <div className="text-white flex flex-col items-center mb-10">
            <Header value="Notícies"/>
              <div className={`grid gap-10 mt-16 mb-10 sm:grid-cols-1 lg:grid-cols-3 sm:w-3/5 w-full ${loaded ? 'opacity-100 transition-opacity duration-1000' : 'opacity-0'}`}>
                {
                  data.map((noticia, index) => (
                    <div key={index} className="flex justify-center">
                      <Noticia noticia={noticia} />
                    </div>
                  ))
                }
              </div>
            <Boton value="Més Notícies" />
          </div>
        </React.Fragment>
      );
}

export default Noticies;