import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import data from './components/data';
import Header from '../../components/header';
import Premiat from './components/premiat';
import PopUp from './components/popUp';

const Premi = () => {
  const { slug } = useParams();

  const [selectedPersona, setSelectedPersona] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = (persona) => {
    setSelectedPersona(persona);
    setShowPopup(true);
  };

  const handleCloseModal  = () => {
    setSelectedPersona(null);
  }

  let URLpremio;
  let titulo;

  switch(slug){
    case 'millor-jugador': URLpremio = 'millorJugador'; titulo= 'Millor jugador'; break;
    case 'millor-jugadora': URLpremio = 'millorJugadora'; titulo= 'Millor jugadora'; break;
    case 'millor-entrenador': URLpremio = 'millorEntrenador'; titulo= 'Millor entrenador/a'; break;
    case 'jugador-projeccio': URLpremio = 'jugadorProjeccio'; titulo= 'Jugador amb més projecció'; break;
    case 'jugadora-projeccio': URLpremio = 'jugadoraProjeccio'; titulo= 'Jugadora amb més projecció'; break;
    case 'maxim-golejador': URLpremio = 'maximGolejador'; titulo= 'Màxim golejador'; break;
    case 'maxima-golejadora': URLpremio = 'maximaGolejadora'; titulo= 'Màxima golejadora'; break;
    case 'millor-jugador-fs': URLpremio = 'millorJugadorFS'; titulo= 'Millor jugador FS'; break;
    case 'millor-jugadora-fs': URLpremio = 'millorJugadoraFS'; titulo= 'Millor jugadora FS'; break;
    case 'millor-entrenador-fs': URLpremio = 'millorEntrenadorFS'; titulo= 'Millor entrenador/a FS'; break;
    case 'millor-arbitre': URLpremio = 'millorArbitre'; titulo= 'Millor àrbitre/a'; break;
    case 'premi-jurat': URLpremio = 'premiJurat'; titulo= 'Premi jurat'; break;
  }

  const premio = data[URLpremio];

  console.log(premio);

  return (
    <React.Fragment>    
      <div className='text-white flex flex-col items-center'>
          <Header value={titulo}/>
          
          <div className={`sm:w-3/5 md:w-full lg:w-3/5 w-full mx-auto grid grid-cols-1 place-items-center ${premio.length === 1 ? 'md:grid-cols-1 ' : premio.length === 2 ? 'md:grid-cols-2' : 'md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'} gap-4 md:my-16`}>

          {
            premio.map((persona, index) => (
              <div onClick={() => togglePopup(persona)}>
                <Premiat key={index} persona={persona} />
              </div>
            ))
          }
          </div>
          {
            showPopup && (
              <PopUp selectedPersona={selectedPersona} handleCloseModal={handleCloseModal}/>
            )
          }
      </div> 
    </React.Fragment>
  );
}

export default Premi;
