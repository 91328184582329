import React, { useState, useEffect } from 'react';
import Header from '../../components/header';

const Bases = () => {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        // Establecer un tiempo de espera antes de marcar la imagen como cargada
        const timeout = setTimeout(() => {
          setLoaded(true);
        }, 200); // Puedes ajustar el tiempo según tus necesidades
    
        // Limpiar el timeout al desmontar el componente para evitar memory leaks
        return () => clearTimeout(timeout);
      }, []);

    return (
        <React.Fragment>
            <div className='text-white flex flex-col items-center'>
                <Header value="Bases"/>
                <div className={`sm:w-3/5 w-11/12 bg-white rounded text-customBlue text-sm sm:text-base p-10 sm:my-12 my-6 ${loaded ? 'opacity-100 transition-opacity duration-1000' : 'opacity-0'}`}>
                    <h2 className='titulo' translate="no">1.- ORGANITZACIÓ, OBJECTE, CALENDARI</h2>
                    <h3 className='subtitulo' translate="no">Organització</h3>
                    <p className='parrafo' translate="no">La Federació Catalana de Futbol (FCF) organitza i convoca amb caràcter anual el premi "Gala Estrelles del Futbol Català" en reconeixement als millors professionals de futbol i futbol sala.</p>
                    <h3 className='subtitulo' translate="no">Objecte</h3>
                    <p className='parrafo' translate="no">En el marc de la "Gala Estrelles del Futbol Català", l’FCF escull els millors jugadors, entrenadors i àrbitres de la temporada anterior, segons el criteri dels seus comitès i professionals. Les candidatures no es presenten ni es retiren, l’FCF escull lliurement els candidats i candidates.</p>
                    <h3 className='subtitulo' translate="no">Calendari</h3>
                    <p className='parrafo' translate="no">L’FCF elabora anualment un calendari, fixant les dates més convenients.</p>
                    <p className='parrafo' translate="no">Es fixa el calendari dels següents actes:</p>
                    <ol className='lista-letras'>
                        <li translate="no">Elecció de candidats i candidates (estructura federativa) </li>
                        <li translate="no">Proclamació de candidats i candidates </li>
                        <li translate="no">Reunió del Jurat  </li>
                        <li translate="no">Proclamació de guanyadors  </li>
                        <li translate="no">Gala de les Estrelles</li>
                    </ol>
                    <h2 className='titulo' translate="no">2.- CANDIDATS I PREMIS</h2>
                    <h3 className='subtitulo' translate="no">Categories</h3>
                    <p className='parrafo' translate="no">Hi ha 12 categories, 8 per futbol, 3 per futbol sala i 1 especial del Jurat, que són les següents:</p>
                    <ul className='lista-puntos'>
                        <li translate="no">Millor jugador (Futbol i F. Sala)</li>
                        <li translate="no">Millor jugadora (Futbol i F. Sala)</li>
                        <li translate="no">Jugador amb més projecció (Futbol)</li>
                        <li translate="no">Jugadora amb més projecció (Futbol)</li>
                        <li translate="no">Millor entrenador/a (Futbol i F. Sala)</li>
                        <li translate="no">Millor àrbitre/a (Futbol)</li>
                        <li translate="no">Premi del Jurat (Jurat)</li>
                        <li translate="no">Premi al màxim golejador català professional (Futbol)</li>
                        <li translate="no">Premi a la màxima golejadora catalana professional (Futbol)</li>
                    </ul>
                    <h3 className='subtitulo' translate="no">Jugadors i jugadores elegibles</h3>
                    <p className='parrafo' translate="no">Poden ser candidats als premis els i les professionals del futbol i del futbol sala nascuts a Catalunya o, que sense ser nascuts a Catalunya, s’hagin format al futbol català durant un mínim de tres temporades en les categories de base.</p>
                    <h3 className='subtitulo' translate="no">Elecció de candidats</h3>
                    <p className='parrafo' translate="no">La Federació proposa 3 candidats per cada una de les categories, excepte en els casos de Premi del Jurat, Premi al Màxim Golejador i Premi a la Màxima Golejadora.</p>
                    <ul className='lista-puntos'>
                        <li translate="no">Els candidats han estat prèviament escollits pels diferents comitès que integren l’FCF (Futbol, Futbol Femení, Comitè Tècnic d’Entrenadors, Comitè Tècnic d’Àrbitres, Lliga Catalana de Futbol Sala).</li>
                        <li translate="no">El premi de designació directa a càrrec del Jurat es decideix durant una reunió prèvia a la celebració de la gala.</li>
                        <li translate="no">Els premis al màxim golejador i la màxima golejadora catalans professionals es decideixen pel número de gols marcats a la lliga i segons el coeficient que s’apliqui a la corresponent lliga. Els coeficients són els mateixos que s’apliquen a la bota d’or. Només es considera el total de gols marcats en lliga, independentment del número de partits o minuts jugats. La lliga ha de ser la primera divisió del país en qüestió, quedant excloses segones divisions, competicions semi-professionals o universitàries. En aquesta categoria de premi, només poden ser nominats/des jugadors/es nascuts/des a Catalunya.</li>
                    </ul>
                    <h3 className='subtitulo' translate="no">Sistema de votació</h3>
                    <p className='parrafo' translate="no">Es basa en la votació del Jurat en la reunió prèvia a la celebració de la gala.L’FCF vetllarà perquè la composició del Jurat es regeixi pel principi de paritat i equilibri de gènere.</p>
                    <p className='parrafo' translate="no">Altres condicions a tenir en compte en la votació:</p>
                    <ul className='lista-puntos'>
                        <li translate="no">El Jurat només pot votar un cop per categoria.</li>
                        <li translate="no">No és obligatori votar en totes les categories.</li>
                        <li translate="no">En cas d’empat, el president del Jurat pot decidir si es dona el premi “ex aequo” o bé si es repeteix la votació entre les candidatures empatades, deixant-ne fora les que tinguin vots inferiors.</li>
                    </ul>
                    <h3 className='subtitulo' translate="no">Terminis</h3>
                    <p className='parrafo' translate="no">Els membres del jurat rebran un dossier informatiu dels nominats i nominades i un enllaç per tal de procedir a la votació els dies previs a la reunió del jurat.</p>
                    <p className='parrafo' translate="no">Les dates de reunió del Jurat i de la Gala, seran fixats per acord de la Junta Directiva de l’FCF.</p>
                    <p className='parrafo' translate="no">Aquestes dates podran ser modificades si es donen circumstàncies que facin aconsellables canviar la planificació inicial.</p>

                </div>
            </div>
        </React.Fragment>
    );
}

export default Bases;