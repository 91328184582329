import React from 'react';
import { RiTwitterXFill, RiFacebookFill, RiInstagramLine, RiYoutubeFill } from "react-icons/ri";
import Estrella from '../assets/img/estrella_2019.png';
import Caixa from '../assets/img/lacaixa_2019.png';

const Footer = () => {

    return (
        <React.Fragment>
            <footer className='w-full bottom-0 left-0 p-2 bg-customBlue overflow-y-auto z-20'>
                <div className='text-footerBlue flex flex-col items-center'>
                    <div className='flex flex-row space-x-1 items-center text-xs md:space-x-4 p-0'>
                        <img src={Estrella} alt='logo_estrella' className='md:w-14 w-11'/>
                        <div id="links" className='space-x-2 md:space-x-3'>
                            <a target="_blank" className='hover:text-gray-400' href="https://www.fcf.cat/"> © 2024 FCF </a>
                            <a target='_blank' className='hover:text-gray-400' href="http://fcf.cat/avis-legal">Avís legal</a>
                            <a target='_blank' className='hover:text-gray-400' href="http://fcf.cat/politica-privacitat">Privacitat</a>
                            <a target='_blank' className='hover:text-gray-400' href="http://fcf.cat/cookies">Cookies</a>
                        </div>
                        <img src={Caixa} alt='logo_caixa' className='md:w-16 w-12'/>
                    </div>
                    
                    <div id="redes" className='flex flex-row text-sm space-x-2 md:space-x-3'>
                        <a target="_blank" className='hover:text-gray-400' href="https://www.facebook.com/FCatalanaFutbol/"><RiFacebookFill/></a>
                        <a target="_blank" className='hover:text-gray-400' href="https://twitter.com/fcf_cat"><RiTwitterXFill/></a>
                        <a target="_blank" className='hover:text-gray-400' href="https://www.youtube.com/channel/UC8sADOGT8FRHDSInSX7HddA"><RiYoutubeFill/></a>
                        <a target="_blank" className='hover:text-gray-400' href="https://www.instagram.com/fcf_cat/"><RiInstagramLine/></a>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
}

export default Footer;