import React from 'react';

const Header = ({value}) => {

    const maxLength = 8;

    const breakLongText = (text) => {
        if (text.length <= maxLength) {
          return text; // No se necesita salto de línea si el texto es corto
        }
    
        // Divide el texto en dos líneas en cada espacio
        const words = text.split(' ');
        const lines = [];
        let currentLine = '';
        words.forEach((word) => {
          if (currentLine.length + word.length + 1 <= maxLength) {
            // Agregar palabra a la línea actual
            if (currentLine !== '') {
              currentLine += ' ';
            }
            currentLine += word;
          } else {
            // Agregar línea actual al array de líneas y comenzar una nueva línea
            lines.push(currentLine);
            currentLine = word;
          }
        });
        lines.push(currentLine); // Agregar la última línea
    
        // Construir el HTML con <br> entre las líneas
        const html = lines.join('<br />');
    
        return html;
      };
    
      // Aplica el salto de línea al texto si es necesario
      const formattedValue = breakLongText(value);

    return (
        <React.Fragment>
            <div className={`${value !== 'Premis' ? 'mt-10 md:mt-0' : ''}`}>
            <h1
              className={`font-bold sm:text-5xl text-3xl background-line-white tracking-widest text-with-line ${value !== 'Premis' ? 'sm:mt-24 mt-10' : ''} ${value.match(/^ÀLBUM (\d+)a GALA$/) ? '' : 'uppercase'}`}
              dangerouslySetInnerHTML={{ __html: formattedValue }}
              style={{ textAlign: 'center' }}
            ></h1>
            </div>
        </React.Fragment>
    );
}

export default Header;