import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Caratula = ({ gala }) => {

  const [loaded, setLoaded] = useState(false);

  const cargarImagen = require.context("../../../assets/img/", true);

  useEffect(() => {
    // Establecer un tiempo de espera antes de marcar la imagen como cargada
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 200); // Puedes ajustar el tiempo según tus necesidades

    // Limpiar el timeout al desmontar el componente para evitar memory leaks
    return () => clearTimeout(timeout);
  }, []);

  return (
    <React.Fragment>
      <div className={`sm:w-full w-10/12 relative group ${loaded ? 'opacity-100 transition-opacity duration-1000' : 'opacity-0'}`}>
          <Link to={{ pathname: `/fotos/gala/${gala[0]}`, state: { variable: gala[3] } }}>
          <div className="group relative m-0 flex h-60 w-full rounded overflow-hidden ring-gray-900/5 sm:mx-auto sm:max-w-lg z-10 bg-gray-200">
              <div className="h-full w-full rounded overflow-hidden opacity-100 transition duration-300 ease-in-out">
                  <img loading="lazy" src={cargarImagen(`./${gala[2]}`)} className="animate-fade-in block h-full w-full scale-100 transform object-cover object-center opacity-100" alt={`Miniatura ${gala[0]}`} />
              </div>
              <div className="bg-white p-3 w-full absolute bottom-0 m-0 pb-4 ps-4">
                  <h1 className="text-lg font-bold text-customBlue ">{gala[1]}</h1>
              </div>
          </div>          
          </Link>
          <div className='absolute -left-0 -top-0  rounded h-60 w-full overflow-hidden transition duration-300 ease-in-out group-hover:translate-x-[-5%] group-hover:translate-y-[-5%]'>
            <img loading="lazy" src={cargarImagen(`./${gala[4]}`)} className='w-full h-60' alt={`Miniatura ${gala[0]}`}/>
          </div>
          <div className='absolute -right-0 -bottom-0  rounded h-60 w-full overflow-hidden transition duration-300 ease-in-out group-hover:translate-x-[5%] group-hover:translate-y-[5%]'>
            <img loading="lazy" src={cargarImagen(`./${gala[5]}`)} className='w-full h-60' alt={`Miniatura ${gala[0]}`}/>
          </div>
      </div>
    </React.Fragment>
  );

  
}

export default Caratula;