import React, { useState } from 'react';
import silueto from '../../assets/img/jurat/silueto.png';

const Tarjeta = ({ persona }) => {
  const [hovered, setHovered] = useState(false);

  const cargarImagen = require.context("../../assets/img/jurat/", true);

  let imagenSrc;
  try {
    // Verificar si la imagen existe en la ruta proporcionada
    imagenSrc = cargarImagen(`./${persona[2]}`);
  } catch (error) {
    // Si no existe, usar la imagen por defecto
    imagenSrc = silueto;
  }

  return (
    <React.Fragment>    
      <div 
        className={`bg-white mb-6 shadow-lg mt-10 w-full rounded transition duration-300 ease-in-out ${hovered ? 'translate-y-1 scale-110' : ''}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className="px-2 flex flex-col">
          <div className="w-full flex justify-center">
          <div className="relative rounded-full overflow-hidden -m-16 -ml-20 lg:-ml-16 shadow-xl w-32 h-32 aspect-w-1 aspect-h-1">
            <img
              src={imagenSrc}
              onError={(e) => { e.target.onerror = null; e.target.src = silueto; }}
              className="rounded-full absolute inset-0 w-full h-full object-cover object-top"
            />
          </div>
          </div>
          <div className="text-center">
              <h3 className="sm:text-lg text-base text-slate-700 uppercase font-bold leading-normal mb-1 pt-20">{persona[0]}</h3>
          </div>
          <div className="p-4 flex justify-center items-center" style={{ height: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <p className="leading-relaxed text-sm text-slate-600" style={{ textAlign: 'center' }}>{persona[1]}</p>
          </div>
        </div>
      </div>  
    </React.Fragment>
  );
}

export default Tarjeta;
