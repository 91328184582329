import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Header from '../../components/header';
import imagenes from './components/imagenes';
import Imagen from './components/imagen';
import ModalComponent from './components/modal';

const Galeria = () => {

    //-----------------Accedemos al id de la url--------
    const { id } = useParams(); 

    // Accede al array de imágenes correspondiente al id de la gala
    const imagenesGala = imagenes[`gala${id}`];
    const titulo = `ÀLBUM ${id}a GALA`;

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [images, setImages] = useState([]);
    

    const handleOnClicked = (image, index) => {
      setSelectedImage(image);
      setSelectedIndex(index);
    }
    
    const handleCloseModal  = () => {
      setSelectedImage(null);
    }

    useEffect(() => {
      // Initialize image data
      setImages(imagenesGala);
    }, [imagenesGala]);

    return (
        <div className='text-white flex flex-col items-center'>
            <Header value={titulo}/>
            <div className='sm:w-3/5 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-6 my-16 '>
                {images.map((image, index) => (
                  <div className='flex justify-center '>
                    <Imagen key={index} image={image} index={index} handleOnClicked={handleOnClicked}/>
                  </div>
                ))}               
                {/*<div className={`fixed inset-0 z-50 transition-opacity duration-1000 ${selectedImage ? 'opacity-100': 'opacity-0 pointer-events-none'}`}>
                  <ModalComponent
                    images={images}
                    selectedIndex={selectedIndex}
                    titulo={titulo}
                    handleCloseModal={handleCloseModal}
                  />
              </div>*/}
              {selectedImage && (
                    <ModalComponent
                      images={images}
                      selectedIndex={selectedIndex}
                      titulo={titulo}
                      handleCloseModal={handleCloseModal}
                    />
                  )}
              
            </div>
        </div>
    );
}

export default Galeria;