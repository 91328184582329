import React from 'react';

const Noticia = ({ noticia }) => {

  // URL de la imagen por defecto
  const imagenPorDefecto = 'http://files.fcf.cat/img/imatge-base-formacio.jpg';  
  // Determinamos la URL de la imagen
  const imagenURL = noticia.IMAGEHOME ? noticia.IMAGEHOME : imagenPorDefecto;

  // Definimos el antetítulo
  let antetituloCortado = noticia.ABSTRACT;

  // Si el antetítulo es demasiado largo, lo recortamos
  if (antetituloCortado.length > 200) {
      let pos = antetituloCortado.lastIndexOf(' ', 185);
      if (pos === -1) {
          antetituloCortado = antetituloCortado.slice(0, 200) + ' [...]';
      } else {
          antetituloCortado = antetituloCortado.slice(0, pos) + ' [...]';
      }
  }

  // Convertir la cadena a un objeto de fecha
  const fecha = new Date(noticia.FECHA);
  
  // Obtener los componentes de la fecha
  const dia = fecha.getDate();
  const mes = fecha.getMonth() + 1; // ¡Recuerda que los meses van de 0 a 11!
  const año = fecha.getFullYear();
  
  // Formatear la fecha en el formato deseado
  const fechaFormateada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${año}`;

  return (
    <React.Fragment>
      <a href={`https://www.fcf.cat/noticia/${noticia.NOTICIA_URL}`} target="_blank" rel="noopener noreferrer" className='sm:w-full w-11/12 '>
        <div id='contenedor' className='bg-white rounded overflow-hidden flex flex-col text-customBlue shadow-lg lg:h-custom'>
          <div>
            <img src={imagenURL} alt="Imagen noticia" className='w-full object-cover' />
          </div>
          <div className='p-3'>
            <div className='text-gray-400 text-xs font-bold flex justify-between uppercase'>
              <p>Gala d'Estrelles</p>
              <p>{fechaFormateada}</p>
            </div>
            <p className='text-lg font-bold mt-4'>{noticia.TITULO}</p>
            <p className='my-4'>{antetituloCortado}</p>
          </div>
        </div>
      </a>
    </React.Fragment>
  );
}

export default Noticia;
