const millorJugador = [
    {nom: 'Marc Cucurella', sexe: 'M', equip: 'Chelsea FC', naixement: 'Alella, 22 de juliol del 1998', categoria: 'Millor jugador de futbol 2023-24', total: '21.43%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/MarcCucurella.png'), alt: "Marc Cucurella", escut: require('../../../assets/img/escuts/2024/chelsea.png'), descripcio: "Format a les categories inferiors del FC Barcelona, Cucurella és un jugador profund i incisiu pel carril esquerra amb una gran capacitat atlètica. La temporada 2018-2019 va canviar els colors blaugranes del Barça pels de la SD Eibar, on ràpidament va demostrar ser un jugador de primer nivell. Després el Getafe CF va apostar per ell i és on es va erigir com a líder en les dues temporades al conjunt madrileny. Allà  va cridar l’atenció d’equips de la Premier League i finalment el Brighton és qui el va fitxar. Amb només un any al Brighton, es va convertir en un dels jugadors més cotitzats d’Anglaterra fins al punt que el Chelsea FC va pagar un traspàs multimilionari per fer-se amb els seus serveis. Aquest passat estiu, Cucurella va enlluernar tothom a l’Eurocopa amb la Selecció Espanyola, amb un paper cabdal en la consecució del títol."},
    {nom: 'Aleix Garcia', sexe: 'M', equip: 'Bayer 04 Leverkusen', naixement: 'Ulldecona, 28 de juny del 1997', categoria: 'Millor jugador de futbol 2023-24', total: '11.9%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/AleixGarcia.png'), alt: "Aleix Garcia", escut: require('../../../assets/img/escuts/2024/Bayer_04_Leverkusen.png'), descripcio: "Aleix Garcia és un migcampista de talent amb una visió de joc privilegiada, que va guiar el Girona FC a la Champions League en la millor temporada de la història del club blanc-i-vermell. Format a la pedrera del Villarreal, Aleix va debutar amb el primer equip groguet per després vestir les samarretes del Manchester City, el Royal Mouscron, FC Dinamo  i l’Eibar fins a liderar l’etapa daurada del Girona FC a Primera Divisió. Actualment és jugador del Bayer Leverkusen, entrenat per Xabi Alonso, després de ser el traspàs més car de la història per un jugador del club blanc-i-vermell, 10 milions d’euros. A més, Aleix Garcia es va convertir en el primer jugador de la història del Girona FC a debutar amb la Selecció Espanyola Absoluta, la temporada 2022-2023."},
    {nom: 'Dani Olmo', sexe: 'M', equip: 'FC Barcelona', naixement: 'Terrassa, 7 de maig del 1998', categoria: 'Millor jugador de futbol 2023-24', total: '66.67%', guanyador: 'Si', src: require('../../../assets/img/nominats/2024/DaniOlmo.png'), alt: "Dani Olmo", escut: require('../../../assets/img/escuts/2024/barcelona.png'), descripcio: "10 anys després de la seva sortida del futbol base del FC Barcelona, Dani Olmo ha tornat aquest 2024 al conjunt blaugrana com un dels fitxatges estrella, després d’abonar els 60 milions d’euros de la clàusula al RB Leipzig. El retorn a casa d’Olmo ha despertat la il·lusió dels culers i més, després després de consagrar-se entre els millors jugadors d’Europa amb un protagonisme extraordinari en la consecució de l’Eurocopa amb la Selecció Espanyola el passat estiu, en què el jugador egarenc va anotar tres gols en un campionat amb segell català. Experiència al futbol croat al Dinamo Zagreb i al RB Leipzig alemany. A la Bundeslliga va créixer com a futbolista on va ser peça clau de l’equip en la consecució de dues Copes i una Supercopa. Recentment ha estat escollit en la posició 15a de la Pilota d’Or."},
];

const millorJugadora = [
    {nom: 'Laia Aleixandri', sexe: 'F', equip: 'Manchester City FC', naixement: 'Santa Coloma de Gramenet, 25 d’agost del 2000', categoria: 'Millor jugadora de futbol 2023-24', total: '4.76%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/LaiaAleixandri.png'), alt: "Laia Aleixandri", escut: require('../../../assets/img/escuts/2024/ManchesterCity.png'), descripcio: "Formada a les categories inferiors del Barça, Laia Aleixandri va sortir del conjunt blaugrana per fer el salt a la Primera Divisió a les files de l’Atlètic de Madrid. Al conjunt matalasser es va convertir en una fixa a l’onze titular i va sumar dues lligues al seu palmarès, a banda de debutar a la màxima competició europea, la Women’s  Champions League. Les bones actuacions no van passar desapercebudes als clubs de màxim nivell i el Manchester City va trucar la porta per fitxar-la com a buc insígnia del nou projecte citizen. Des de fa dues temporades, Aleixandri és la batuta del conjunt de Manchester amb un domini i visió de joc de qualitat. En categories inferiors ha aconseguit un Europeu sub17 i ha estat sotscampiona del Mundial sub20. Amb la Selecció Absoluta va disputar aquest passat estiu els Jocs Olímpics de París 2024. Aleixandri va guanyar el premi a Jugadora Projecció de la 8a Gala de les Estrelles."},
    {nom: 'Ona Batlle', sexe: 'F', equip: 'FC Barcelona', naixement: 'Vilassar de Mar, 10 de juny del 1999', categoria: 'Millor jugadora de futbol 2023-24', total: '4.76%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/OnaBatlle.png'), alt: "Ona Batlle", escut: require('../../../assets/img/escuts/2024/barcelona.png'), descripcio: "Formada a les categories inferiors del Barça, va signar una temporada pel Madrid CFF per després anar al Levante UD. L’any 2020 va fitxar pel Manchester United FC, on es va convertir en una titular indiscutible, sent una de les jugadores més destacades de l’equip anglès. Campiona del món amb la Selecció Espanyola Absoluta a Austràlia l’estiu del 2023 en què també va fitxar pel FC Barcelona. S’ha convertit en una peça important en l’esquema blaugrana tant en la seva posició de lateral dret, com també a cama canviada per la banda esquerra. En la seva primera temporada al conjunt culer ha guanyat un pòquer de títols amb la Champions, Lliga, Copa i Supercopa. Ona Batlle és una jugadora decidida i veloç. Soluciona els problemes defensius amb la mateixa naturalitat que quan se suma a l’atac. Es tracta d’una lateral dret amb un enorme recorregut que no dona mai una pilota per perduda. En categories inferiors ha aconseguit dues Eurocopes sub 17 i sub 19 els anys 2015 i 2017."},
    {nom: 'Aitana Bonmatí', sexe: 'F', equip: 'FC Barcelona', naixement: 'Sant Pere de Ribes, 18 de gener del 1998', categoria: 'Millor jugadora de futbol 2023-24', total: '90.48%', guanyador: 'Si', src: require('../../../assets/img/nominats/2024/AitanaBonmati.png'), alt: "Aitana Bontami", escut: require('../../../assets/img/escuts/2024/barcelona.png'), descripcio: "La flamant Pilota d’Or per segon cop consecutiu ja apuntava maneres des de ben petita al CD Ribes, el club de la seva localitat natal. Després de jugar al CF Cubelles, va donar el salt al FC Barcelona amb catorze anys. El 2017 va començar la seva trajectòria a la Selecció Espanyola,  jugant en totes les categories i aconseguint dues Eurocopes (sub 17 i sub 19). La seva vitrina esportiva està plena de títols: 3 Women’s Champions League, 5 lligues espanyoles, 6 copes d’Espanya, 4 Supercopes i una copa del món. A nivell individual recentment ha estat premiada amb la Pilota d’Or per segona vegada. Triple guanyadora al premi a ‘Millor Jugadora’ de la Gala de les Estrelles en la 7a (2018), 8a edició (2019) i 11a edició (2023), i també guanyadora de ‘Jugadora Projecció’ l’any 2017 (6a edició)."},
];

const millorEntrenador = [
    {nom: 'F. Xavier Garcia Pimienta', sexe: 'M', equip: 'Sevilla FC', naixement: 'Barcelona, 3 d’agost del 1974', categoria: 'Millor entrenador/a de futbol 2023-24', total: '2.38%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/GarciaPimienta.png'), alt: "Francesc Xavier Garcia Pimienta", escut: require('../../../assets/img/escuts/2024/Sevilla.jpg'), descripcio: "Durant 12 anys va defensar la samarreta del FC Barcelona com a jugador, i després es va desenvolupar a les banquetes de La Masia fins a dirigir el filial blaugrana de la temporada 17-18 a la 20-21. Garcia Pimienta coneix molt bé el model Barça i és un dels grans exponents des de la pissarra. Un cop superada l’etapa al Barça Atlètic, va ser a la UD Las Palmas on va assolir l’èxit a l’elit amb l’ascens a Primera Divisió i posteriorment mantenir la categoria de forma còmode i amb un estil de joc enlluernador. Aquesta temporada ha fitxat pel Sevilla FC i, després de 7 jornades, ha signat la renovació fins al 2027."},
    {nom: 'Pep Guardiola', sexe: 'M', equip: 'Manchester City FC', naixement: 'Santpedor, 18 de gener del 1971', categoria: 'Millor entrenador/a de futbol 2023-24', total: '88.1%', guanyador: 'Si', src: require('../../../assets/img/nominats/2024/PepGuardiola.png'), alt: "Pep Guardiola", escut: require('../../../assets/img/escuts/2024/ManchesterCity.png'), descripcio: "Considerat l’entrenador del millor Barça de la història, ha construït el seu prestigi a través de la seva filosofia, disciplina i èxits. Per quarta temporada consecutiva va aconseguir aixecar el títol de la Premier League, un fet inèdit en la història de la competició anglesa. A més, també va guanyar la Supercopa d’Europa i el Mundial de Clubs (per primera vegada pel Manchester City). Al seu palmarès com a entrenador hi ha un total de 39 títols en tres països diferents, convertint-se en un dels entrenadors més exitosos de la història. Guardiola repeteix nominació com a ‘Millor Entrenador’ català, un guardó que ha guanyat en sis ocasions: 1a edició (2012), 5a edició (2016), 8a edició (2019), 9a edició (2021), 10a edició (2022) i 11a edició (2023)."},
    {nom: 'Bartolomé "Tintín" Márquez', sexe: 'M', equip: 'Selecció Qatar', naixement: 'Barcelona, 7 de gener del 1962', categoria: 'Millor entrenador/a de futbol 2023-24', total: '9.52%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/TintinMarquez.png'), alt: "Bartolomé Tintín Márquez", escut: require('../../../assets/img/escuts/2024/Qatar.png'), descripcio: "‘Tintín’ Márquez ha entrat a la història del futbol qatarià. Arribar i moldre, després de dos mesos dirigint la selecció del Qatar, el tècnic barceloní va aconseguir aixecar el títol continental amb el combinat del Qatar en una final decidida als penals davant Jordània, al costat de Luis Garcia. ‘Tintín’ Márquez és una llegenda de l’Espanyol, on després de penjar les botes va créixer com a tècnic a la Dani Jarque fins a agafar les regnes del primer equip perico. Abans, havia estat tècnic assistent de Miguel Ángel Lotina i Ernesto Valverde. La seva trajectòria a les banquetes l’ha portat a Bèlgica i  a Iraq."},
];

const jugadorProjeccio = [
    {nom: 'Pau Cubarsí', sexe: 'M', equip: 'FC Barcelona', naixement: "Estanyol, 22 de gener del 2007", categoria: 'Jugador amb més projecció 2023-24', total: '9.76%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/PauCubarsi.png'), alt: "Pau Curbasí", escut: require('../../../assets/img/escuts/2024/barcelona.png'), descripcio: "Format al planter del FC Barcelona, Pau Cubarsí ha estat dels darrers descobriments de La Masia amb protagonisme al primer equip. Encara sent menor d’edat, el central nascut a Estanyol va debutar a mitjans de la temporada passada i des del primer moment es va erigir com a indiscutible a l’eix de la defensa. Les seves bones actuacions van provocar el seu debut amb la selecció espanyola amb qui a l’estiu va guanyar la medalla d’or als Jocs Olímpics de París."},
    {nom: 'Joan Garcia', sexe: 'M', equip: 'RCD Espanyol', naixement: 'Sallent, 4 de maig del 2001', categoria: 'Jugador amb més projecció 2023-24', total: '14.63%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/JoanGarcia.png'), alt: "Joan Garcia", escut: require('../../../assets/img/escuts/2024/ESPANYOL.png'), descripcio: "Joan Garcia és considerat un dels millors porters del futbol espanyol després de ser un dels herois de l’ascens del RCD Espanyol 2023-2024 a Primera Divisió. Format entre el planter del CF Damm i la Dani Jarque, Garcia ha picat pedra per consolidar-se sota els pals de la porteria de Cornellà – El Prat. Amb 23 anys, aquest passat estiu es va penjar la medalla d’or als Jocs Olímpics amb la Selecció Espanyola i és un dels porters més desitjats en el panorama futbolístic europeu."},
    {nom: 'Lamine Yamal', sexe: 'M', equip: 'FC Barcelona', naixement: 'Esplugues de Llobregat, 13 de juliol del 2007', categoria: 'Jugador amb més projecció 2023-24', total: '75.61%', guanyador: 'Si', src: require('../../../assets/img/nominats/2024/LamineYamal.png'), alt: "Lamine Yamal", escut: require('../../../assets/img/escuts/2024/barcelona.png'), descripcio: "Lamine Yamal ha estat una de les irrupcions més sonades en el futbol mundial dels darrers anys. Debut amb el primer equip del FC Barcelona al final de la temporada 2022-2023, tenint edat de cadet de segon any, el jugador blaugrana va trencar pràcticament tots els rècords establerts de precocitat tant amb el Barça com amb la selecció espanyola. Amb una esquerra de seda s’ha destapat com a golejador i assistent. Sent menor d’edat ja compta en el seu palmarès amb l’Eurocopa aconseguida amb ‘La Roja’ en què Lamine Yamal va anotar un gol i repartir quatre assistències en els set partits que va disputar."},
];

const jugadoraProjeccio = [
    {nom: 'Ylenia Estrella', sexe: 'F', equip: 'RCD Espanyol', naixement: "Castellbell i el Vilar, 17 de juliol del 2005", categoria: 'Jugadora amb més projecció 2023-24', total: '21.95%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/YleniaEstrella.png'), alt: "Ylenia Estrella", escut: require('../../../assets/img/escuts/2024/ESPANYOL.png'), descripcio: "La davantera del RCD Espanyol, formada al Levante Badalona, va ser la jugadora més destacada del filial blanc-i-blau la temporada passada a la Segona Federació. Les seves bones actuacions i el seu joc associatiu l’han dut a fer el salt al primer equip en la seva tornada a la Primera Divisió. Ylenia està tornant amb esforç i treball la confiança que Sara Monforte diposita en ella. No dona una pilota per perduda i és una de les revulsives en atac del conjunt blanc-i-blau."},
    {nom: 'Carla Julià', sexe: 'F', equip: 'FC Levante Badalona', naixement: 'Barcelona, 14 de desembre del 2006', categoria: 'Jugadora amb més projecció 2023-24', total: '36.59%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/CarlaJulia.png'), alt: "Carla Julià", escut: require('../../../assets/img/escuts/2024/LevanteBadalona.jpg'), descripcio: "La seva trajectòria està marcada pel pas pel futbol base del RCD Espanyol on hi va estar set anys. En categoria juvenil va fitxar pel CF Damm i a l’estiu del 2023 va arribar al filial del FC Levante Las Planas. És una jugadora formada també a les seleccions catalanes base on va aconseguir un subcampionat sub17 amb la quadribarrada. A mitjans de la temporada 2023-2024 Carla Julià va debutar amb el primer equip del FC Levante Las Planas contra el FC Barcelona a l’estadi Johan Cruyff. És una futbolista amb molt talent i amb projecció pel futbol femení català del futur."},
    {nom: 'Celia Segura', sexe: 'F', equip: 'FC Barcelona', naixement: 'Barcelona, 10 de març del 2007', categoria: 'Jugadora amb més projecció 2023-24', total: '41.46%', guanyador: 'Si', src: require('../../../assets/img/nominats/2024/CeliaSegura.png'), alt: "Celia Segura", escut: require('../../../assets/img/escuts/2024/barcelona.png'), descripcio: "Una de les futbolistes més prometedores del futbol base del FC Barcelona. Forjada a La Masia, Celia Segura ha establert registres golejadors increïbles com els 121 gols en una sola temporada en categoria Aleví i jugant contra equips masculins. A més, també és la jugadora més jove del club a tenir fitxa del filial. Celia Segura va passar per les seleccions catalanes base on va aconseguir un subcampionat sub17. En el seu palmarès també hi figura una medalla de plata en el Mundial sub17 amb la selecció espanyola en què Celia Segura va ser la tercera màxima golejadora del campionat i la tercera millor jugadora."},
];

const maximGolejador = [
    {nom: 'Gerard Moreno', sexe: 'M', equip: 'Vila-Real CF', naixement: "Santa Perpètua de Mogoda, 7 d’abril del 1992", src: require('../../../assets/img/nominats/2024/GerardMoreno.png'), alt: "Gerard Moreno", escut: require('../../../assets/img/escuts/2024/Villarreal_CF_logo.svg.png'), descripcio: "10 gols a la lliga amb el Villarreal CF la temporada passada han convertit Gerard Moreno en el màxim golejador. És un davanter intuïtiu, llest i amb un gran olfacte golejador que ha fet carrera al Badalona, al Villarreal, al Mallorca i a l’Espanyol on va esdevenir un dels futbolistes més estimats per l’afició. Entre les seves principals virtuts destaca un poderós joc aeri, capacitat per combinar amb els seus companys i una fabulosa fiabilitat en la definició. El curs 2020-2021 va estar nominat a millor jugador de la UEFA després de conquerir la final de l’Europa League amb el Villarreal -va marcar 7 gols en 12 partits-. Gràcies a això va aconseguir vestir la samarreta de la selecció espanyola, i va ser candidat a la Pilota d’Or. La passada temporada també va ser el màxim golejador, amb 7 gols, empatat amb Javi Puado. En la 9a edició de la Gala Estrelles 2021 va rebre el premi a millor jugador i màxim golejador."}
];

const maximaGolejadora = [
    {nom: 'Claudia Pina', sexe: 'F', equip: 'FC Barcelona', naixement: "Montcada i Reixac, 12 d’agost del 2001", src: require('../../../assets/img/nominats/2024/ClaudiaPina.png'), alt: "Claudia Pina", escut: require('../../../assets/img/escuts/2024/barcelona.png'), descripcio: "Amb 13 gols Claudia Pina és la màxima golejadora catalana de la temporada 2023-2024. Des de ben petita, Pina ha estat una superdotada amb la pilota als peus. Els inicis de Claudia Pina van ser a la Dani Jarque per després incorporar-se a La Masia. Pilota d’Or al Mundial sub 17 i millor jugadora de l’edició, Pina es va fer un forat entre les grans. Després d’un magnífic curs al Sevilla FC, en forma de cessió, està encadenant temporades a la disciplina del primer equip del FC Barcelona demostrant l’enorme potencial i olfacte golejador. El gol forma part del seu ADN. Pòquer de títols amb el FC Barcelona la temporada passada: Lliga, Copa, Lliga de Campions i Supercopa, amb una participació clau."},
];

const millorJugadorFS = [
    {nom: 'Adolfo Fernández', sexe: 'M', equip: 'Barça', naixement: 'Santa Coloma de Gramenet, 19 de maig del 1993', categoria: 'Millor jugador de futbol sala 2023-24', total: '39.02%', guanyador: 'Si', src: require('../../../assets/img/nominats/2024/AdolfoFernandez.png'), alt: "Adolfo Fernández", escut: require('../../../assets/img/escuts/2024/barcelona.png'), descripcio: "Format als equips inferiors de l’Industrias Santa Coloma, el 2009 debuta amb el primer equip colomenc. Després de ser escollit jugador revelació de la temporada 2013-2014 va fitxar pel Barça. Va ser elegit el millor jugador de la Lliga Nacional de Futbol Sala la temporada 2019-2020. També és un habitual amb la selecció espanyola absoluta. El pivot blaugrana posseeix capacitat de superació, aprenentatge i una gran fortalesa mental. Adolfo Fernández compta amb dos títols de ‘Millor Jugador de Futbol Sala’ de la 5a (2016) i la 9a (2021) edició de la Gala de les Estrelles. Amb el Barça ha guanyat multitud de títols, entre els quals 4 lligues i 2 Champions. La temporada passada va assolir la Copa d’Espanya."},
    {nom: 'Dídac Plana', sexe: 'M', equip: 'Barça', naixement: 'Arenys de Mar, 22 de maig del 1990', categoria: 'Millor jugador de futbol sala 2023-24', total: '29.27%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/DidacPlana.png'), alt: "Dídac Plana", escut: require('../../../assets/img/escuts/2024/barcelona.png'), descripcio: "Després de diferents temporades al Guadalajara, Sala 10 Saragossa, Industrias Santa Coloma i Jaén Paraiso, el Barça sempre ha estat la seva destinació final. Des de l’estiu del 2018 defensa la porteria blaugrana ininterrompudament. Segueix sent un habitual de la porteria de la selecció espanyola absoluta. Dídac Plana és un dels millors porters de la lliga. Una assegurança sota els pals, on destaca per la seva agilitat, reflexos i grans aturades. A més, ja ha estat premiat com el ‘Millor Jugador de Futbol Sala’ català en la 8a (2019) i en la 10a (2022) edició de la Gala de les Estrelles. Amb el Barça ha aconseguit 4 lligues, 2 Champions i altres títols. La temporada passada va assolir la Copa d’Espanya."},
    {nom: 'Álex Verdejo', sexe: 'M', equip: 'Industrias Santa Coloma', naixement: 'Barcelona, 20 de juliol del 1989', categoria: 'Millor jugador de futbol sala 2023-24', total: '31.71%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/AlexVerdejo.png'), alt: "Álex Verdejo", escut: require('../../../assets/img/escuts/2024/industriesSantaColoma.png'), descripcio: "Jugador molt implicat amb Industrias Santa Coloma on és un dels pesos pesants del vestidor. Amb el conjunt colomenc any rere any aconsegueixen ser un dels equips amb el joc més vistós de la primera divisió. La temporada passada es van quedar a un pas d’accedir al play-off pel títol de lliga. Abans d’arribar a Industrias va formar part de diversos clubs com el Barça B, L’Hospitalet Bellsport, Zaragoza, Peñíscola i Santiago Futsal. Verdejo també ha demostrat el seu compromís amb la Selecció Catalana en què va ser el capità quadribarrat en el darrer partit contra Galícia del passat 11 de setembre."},
];

const millorJugadoraFS = [
    {nom: 'Alicia Benete', sexe: 'F', equip: 'FSF Móstoles', naixement: 'Barcelona, 12 de febrer del 1997', categoria: 'Millor jugadora de futbol sala 2023-24', total: '20%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/AliciaBenete.png'), alt: "Alicia Benete", escut: require('../../../assets/img/escuts/2024/F.S.F_Mostoles.png'), descripcio: "La seva carrera va començar al FS Castelldefels i a l’AE Penya Esplugues. Amb el CEFS Rubí va arribar a la màxima categoria femenina i, poc després, va marxar al Leganés FS. Just després va emprendre una aventura als Estats Units, dedicada als estudis i on va provar el Futbol 11. Tanmateix, a la temporada 2018-2019 va tornar a Espanya fitxant pel Móstoles. Guanyadora del premi a ‘Millor Jugadora de futbol sala’ en la 9a edició de la Gala de les Estrelles (2021), Benete repeteix nominació gràcies a les seves actuacions destacades amb el FSF Móstoles. Més que consagrada a la Primera RFEF Futsal Femenina, cada temporada fa mèrits per ser una de les màximes golejadores de la competició. Fins i tot, Alícia Benete va arribar a debutar amb la Selecció Espanyola absoluta, l’any 2021, i ho va fer amb gol contra la República Txeca."},
    {nom: 'Arantxa Medina', sexe: 'F', equip: 'Rubí CEFS', naixement: 'Barcelona, 6 de juny del 1991', categoria: 'Millor jugadora de futbol sala 2023-24', total: '35%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/ArantxaMedina.png'), alt: "Arantxa Medina", escut: require('../../../assets/img/escuts/2024/RubiCEFS.png'), descripcio: "L’Esportiu Rubí FS enguany ha retornat a l’elit a la Primera RFEF Futsal Femenina set anys després en què Arantxa Medina és la capitana i líder de l’equip. La temporada passada l’equip del Vallès va assolir l’ascens després d’imposar-se en la final del play-off al Bembrive gallec en què la futbolista catalana va ser una de les peces claus. Una temporada de consolidació amb l’Esportiu Rubí femení, retorna a l’elit del futsal femení on ja havia jugat anteriorment 6 temporades amb el mateix Esportiu Rubí i Penya Esplugues, situació que l’ha situat en primer pla. A més, el passat 11 de setembre es va tornar a posar la samarreta de la Selecció Catalana per jugar contra Galícia."},
    {nom: 'Laia Rojo', sexe: 'F', equip: 'AE Les Corts UBAE', naixement: '29 de juny del 2004', categoria: 'Millor jugadora de futbol sala 2023-24', total: '45%', guanyador: 'Si', src: require('../../../assets/img/nominats/2024/LaiaRojo.png'), alt: "Laia Rojo", escut: require('../../../assets/img/escuts/2024/les_corts.png'), descripcio: "Talent forjat a l’AE Les Corts UBAE i a les seleccions catalanes base. Jove jugadora que amb el conjunt barceloní competeix a la Primera RFEF Futsal Femenina per segon any consecutiu, una fita històrica per l’entitat. La temporada passada, la del debut a l’elit estatal, van aconseguir classificar-se per a la Fase Final de la Copa de la Reina. Amb les seleccions catalanes base de futbol sala, Laia Rojo ha aconseguit diversos cops guanyar el Campionat d’Espanya en diverses categories. Un cop superada l’etapa juvenil, el passat 11 de setembre va debutar amb Catalunya en el duel contra Galícia. A més, també va debutar amb la selecció absoluta espanyola, el passat mes de març, per a disputar un torneig amistós a Portugal."},
];

const millorEntrenadorFS = [
    {nom: 'Xavi Closas', sexe: 'M', equip: 'Industrias Santa Coloma', naixement: 'Barcelona, 29 de juliol del 1978', categoria: 'Millor entrenador/a de futbol sala 2023-24', total: '41.03%', guanyador: 'Si', src: require('../../../assets/img/nominats/2024/XaviClosas.png'), alt: "Xavi Closas", escut: require('../../../assets/img/escuts/2024/industriesSantaColoma.png'), descripcio: "Xavi Closas va arribar a l’estiu del 2022 a la banqueta d’Industrias Santa Coloma i, des de llavors, l’equip colomenc s’ha consolidat entre els millors de la Primera Divisió masculina de futbol sala. Fins i tot, a la temporada anterior, va aconseguir guanyar la Copa Catalunya de futbol sala, en una final trepidant davant el Barça amb el resultat de 3 a 2, suposant la tercera Copa Catalunya de la història del club colomenc. A més, l’Industrias Santa Coloma va realitzar una temporada notable quedant-se a les portes dels play-off de lliga."},
    {nom: 'Chus López', sexe: 'M', equip: 'BTS Rekord Bielsko-Biala', naixement: 'Hospitalet de Llobregat, 23 de gener del 1987', categoria: 'Millor entrenador/a de futbol sala 2023-24', total: '25.64%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/ChusLopez.png'), alt: "Chus Lopez", escut: require('../../../assets/img/escuts/2024/Rekord_Bielsko_Biala.png'), descripcio: "Chus Lopez va ser format al Miró Martorell, i va ser un dels porters més joves en debutar a Primera Divisió masculina de futbol sala. Com a porter, també va jugar al Playas de Salou, al Benicarló FS i al Palma Futsal. A la temporada 2015-2016 va fitxar pel KS Orzeł Futsal Jelcz-Laskowice, de la Segona Divisió de Polònia.  Una temporada més tard, rep l’oferta de ser l’entrenador del mateix equip polonès i a la primera temporada aconsegueix una segona posició històrica pel club. A la temporada següent, el tècnic fa el salt al Rekord Bielsko-Biala, sent campió de la Copa de Polònia el maig del 2022 i de la Supercopa al setembre del mateix any. Només se li resistia la lliga i, finalment, la va aconseguir la temporada passada."},
    {nom: 'Dani Martínez', sexe: 'M', equip: 'El Pozo Murcia', naixement: 'Barcelona, 27 d’agost del 1987', categoria: 'Millor entrenador/a de futbol sala 2023-24', total: '33.33%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/DaniMartinez.png'), alt: "Dani Martinez", escut: require('../../../assets/img/escuts/2024/El_Pozo.jpg'), descripcio: "Capgirar la dinàmica. Dani Martínez va arribar al cos tècnic d’El Pozo Múrcia a l’agost del 2022 com a segon entrenador i preparador físic, procedent de l’Industrias Santa Coloma. Després de la destitució de Javi Rodríguez, al febrer del 2024 i en una situació complicada de l’equip, Dani Martínez va ser capaç de capgirar la dinàmica negativa de l’equip per arribar a ser subcampió de la Copa d’Espanya i subcampió de la lliga després de perdre la final davant el Jimbee Cartagena. Enguany, El Pozo Murcia es troba a la part alta de la classificació de Primera Divisió masculina de futbol sala."},
];

const millorArbitre = [
    {nom: 'Ainara Acevedo', sexe: 'F', naixement: 'Rosario, 15 d’agost del 1991', categoria: 'Millor àrbitre/a 2023-24', total: '43.9%', guanyador: 'Si', src: require('../../../assets/img/nominats/2024/AinaraAcevedo.png'), alt: "Ainara Acevedo", descripcio: "L’àrbitra de referència del futbol català. Reconeguda com a millor àrbitra per la RFEF l’any 2020, i amb el Premi Dona i Esport 2017, Ainara Acevedo segueix acumulant èxits en el món de l’arbitratge. Amb vuit temporades a l’elit del futbol femení, és àrbitra Internacional FIFA des del 2018.  Ainara Acevedo té una gran experiència a nivell nacional i internacional amb participacions a la Women’s Champions League i a competicions de seleccions. Com a àrbitra al futbol català, Acevedo també xiula partits a la Tercera Federació Masculina des de fa dues temporades. Ainara Acevedo ha guanyat el premi a millor àrbitra a la Gala de les Estrelles l’any 2019."},
    {nom: 'Víctor García', sexe: 'M', naixement: 'Sant Iscle de Vallalta, 12 d’octubre del 1994', categoria: 'Millor àrbitre/a 2023-24', total: '36.59%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/VictorGarcia.png'), alt: "Víctor García", descripcio: "La seva carrera ha estat fulgurant. El maresmenc es va afiliar al Comitè Tècnic d’Àrbitres de l’FCF al gener del 2011 i des de llavors ha assolit ascensos consecutivament fins a arribar a la Segona Divisió ‘B’, on s’hi va estar cinc temporades. La 2021-2022 va fer el salt al futbol professional i, dos anys més tard, va assolir l’ascens a la Primera Divisió, com a àrbitre principal. Amb 28 anys va ser distingit com el millor col·legiat de la Segona Divisió ‘A’ amb el Trofeu Guruceta en la seva primera temporada a la categoria. En la seva estrena a la màxima categoria ha dirigit un total del 19 partits."},
    {nom: 'Ylenia Sánchez', sexe: 'F', naixement: 'L’Arboç, 29 de juny del 1995', categoria: 'Millor àrbitre/a 2023-24', total: '19.51%', guanyador: 'No', src: require('../../../assets/img/nominats/2024/YleniaSanchez.png'), alt: "Ylenia Sanchez", descripcio: "Ylenia Sánchez s’ha guanyat el respecte a la Primera Divisió femenina gràcies a la seva bona feina i al seu esforç. Amb plena dedicació en l’arbitratge, Ylenia Sánchez suma vuit temporades a l’elit del futbol femení convertint-se en una de les referents de la plantilla d’àrbitres de Primera Divisió. Gràcies a la seva trajectòria, Sánchez xiula cada vegada més de partits de màxim nivell espanyol i internacional. En futbol masculí, Ylenia Sánchez xiula partits a la Tercera Federació Masculina des de fa dues temporades."},
];

const premiJurat = [
    {nom: 'Girona FC', src: require('../../../assets/img/nominats/2024/GironaFC.png'), alt: "Girona FC", descripcio: "El Jurat ha decidit atorgar el premi al Girona FC per la seva temporada 23/24 històrica acabant en la tercera posició de la lliga espanyola i aconseguint la classificació per la UEFA Champions League."}
];

export default {
    millorJugador: millorJugador,
    millorJugadora: millorJugadora,
    millorEntrenador: millorEntrenador,
    jugadorProjeccio: jugadorProjeccio,
    jugadoraProjeccio: jugadoraProjeccio,
    maximGolejador: maximGolejador,
    maximaGolejadora: maximaGolejadora,
    millorJugadorFS: millorJugadorFS,
    millorJugadoraFS: millorJugadoraFS,
    millorEntrenadorFS: millorEntrenadorFS,
    millorArbitre: millorArbitre,
    premiJurat: premiJurat
};