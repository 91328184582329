import React, { useState, useEffect } from 'react';
import cat from '../../assets/img/3cat.png';
import Header from '../../components/header';
import Xapa from './components/xapa.js';

const Premis = () => {

    const [loaded, setLoaded] = useState(false);

    const premis = [
        ['millor-jugador', 'xapa01'],
        ['millor-jugadora', 'xapa02'],
        ['jugador-projeccio', 'xapa04'],
        ['jugadora-projeccio', 'xapa05'],
        ['millor-entrenador', 'xapa03'],
        ['millor-arbitre', 'xapa11'],
        ['maxim-golejador', 'xapa06'],
        ['maxima-golejadora', 'xapa07'],
        ['millor-jugador-fs', 'xapa08'],
        ['millor-jugadora-fs', 'xapa09'],
        ['millor-entrenador-fs', 'xapa10'],
        ['premi-jurat', 'xapa12'],
    ];

    useEffect(() => {
        // Establecer un tiempo de espera antes de marcar la imagen como cargada
        const timeout = setTimeout(() => {
          setLoaded(true);
        }, 200); // Puedes ajustar el tiempo según tus necesidades
    
        // Limpiar el timeout al desmontar el componente para evitar memory leaks
        return () => clearTimeout(timeout);
      }, []);

    return (
        <React.Fragment>
            <div className='text-white flex flex-col items-center'>
                <div className='w-full flex flex-col items-center mt-14 mb-4'>
                    {/*<p>Dimecres 4 de desembre de 2024 a les 19.30h</p>*/}
                    <div className='flex flex-row items-center'>
                        <p>En directe per</p>               
                        <img src={cat} alt='3cat' className='w-20 ml-2'/>     
                    </div>
                </div>
                <Header value='Premis'/>
                <div className={`sm:w-2/3 w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 my-24 ${loaded ? 'opacity-100 transition-opacity duration-1000' : 'opacity-0'}`}>
                {
                    premis.map((premi, index) => (
                        <div key={index} className='flex justify-center'>
                            <Xapa premi={premi}/>
                        </div>
                    ))
                }
                </div>
            </div>
            
        </React.Fragment>
    );
}

export default Premis;