const imagenesGala1 = [
    {src: require('../../../assets/img/gala1/foto01.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto02.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto03.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto04.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto05.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto06.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto07.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto08.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto09.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto10.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto11.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto12.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto13.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto14.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto15.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto16.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto17.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto18.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto19.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto20.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto21.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto22.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto23.jpg'), alt: "imagen_gala1"},
    {src: require('../../../assets/img/gala1/foto24.jpg'), alt: "imagen_gala1"},
];

const imagenesGala2 = [
    {src: require('../../../assets/img/gala2/foto01.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto02.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto03.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto04.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto05.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto06.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto07.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto08.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto09.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto10.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto11.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto12.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto13.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto14.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto15.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto16.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto17.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto18.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto19.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto20.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto21.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto22.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto23.jpg'), alt: "imagen_gala2"},
    {src: require('../../../assets/img/gala2/foto24.jpg'), alt: "imagen_gala2"},
];

const imagenesGala3 = [
    {src: require('../../../assets/img/gala3/foto01.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto02.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto03.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto04.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto05.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto06.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto07.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto08.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto09.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto10.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto11.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto12.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto13.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto14.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto15.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto16.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto17.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto18.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto19.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto20.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto21.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto22.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto23.jpg'), alt: "imagen_gala3"},
    {src: require('../../../assets/img/gala3/foto24.jpg'), alt: "imagen_gala3"},
];

const imagenesGala4 = [
    {src: require('../../../assets/img/gala4/foto01.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto02.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto03.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto04.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto05.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto06.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto07.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto08.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto09.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto10.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto11.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto12.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto13.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto14.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto15.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto16.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto17.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto18.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto19.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto20.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto21.jpg'), alt: "imagen_gala4"},
    {src: require('../../../assets/img/gala4/foto22.jpg'), alt: "imagen_gala4"},
];

const imagenesGala5 = [
    {src: require('../../../assets/img/gala5/foto01.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto02.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto03.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto04.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto05.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto06.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto07.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto08.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto09.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto10.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto11.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto12.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto13.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto14.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto15.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto16.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto17.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto18.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto19.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto20.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto21.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto22.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto23.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto24.jpg'), alt: "imagen_gala5"},
    {src: require('../../../assets/img/gala5/foto25.jpg'), alt: "imagen_gala5"},
];

const imagenesGala6 = [
    {src: require('../../../assets/img/gala6/foto01.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto02.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto03.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto04.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto05.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto06.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto07.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto08.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto09.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto10.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto11.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto12.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto13.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto14.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto15.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto16.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto17.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto18.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto19.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto20.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto21.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto22.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto23.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto24.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto25.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto26.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto27.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto28.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto29.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto30.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto31.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto32.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto33.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto34.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto35.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto36.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto37.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto39.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto40.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto41.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto42.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto43.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto44.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto45.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto46.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto47.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto48.jpg'), alt: "imagen_gala6"},
    {src: require('../../../assets/img/gala6/foto50.jpg'), alt: "imagen_gala6"},
];

const imagenesGala7 = [
    {src: require('../../../assets/img/gala7/foto01.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto02.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto03.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto04.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto05.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto06.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto07.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto08.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto09.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto10.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto11.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto12.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto13.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto14.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto15.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto16.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto17.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto18.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto19.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto20.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto21.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto22.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto23.jpg'), alt: "imagen_gala7"},
    {src: require('../../../assets/img/gala7/foto24.jpg'), alt: "imagen_gala7"},
];

const imagenesGala8 = [
    {src: require('../../../assets/img/gala8/foto01.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto02.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto03.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto04.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto05.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto06.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto07.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto08.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto09.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto10.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto11.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto12.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto13.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto14.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto15.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto16.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto17.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto18.jpg'), alt: "imagen_gala8"},
    {src: require('../../../assets/img/gala8/foto19.jpg'), alt: "imagen_gala8"},
];

const imagenesGala9 = [
    {src: require('../../../assets/img/gala9/gala9_1.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_2.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_3.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_4.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_5.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_6.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_7.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_8.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_9.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_10.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_11.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_12.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_13.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_14.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_15.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_16.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_17.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_18.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_19.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_20.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_21.jpg'), alt: "imagen_gala9"},
    {src: require('../../../assets/img/gala9/gala9_22.jpg'), alt: "imagen_gala9"},
];

const imagenesGala10 = [
    {src: require('../../../assets/img/gala10/gala10_1.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_2.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_3.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_4.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_5.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_6.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_7.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_8.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_9.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_10.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_11.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_12.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_13.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_14.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_15.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_16.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_17.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_18.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_19.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_20.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_21.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_22.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_23.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_24.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_25.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_26.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_27.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_28.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_29.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_30.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_31.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_32.jpg'), alt: "imagen_gala10"},
    {src: require('../../../assets/img/gala10/gala10_33.jpg'), alt: "imagen_gala10"},
];

const imagenesGala11 = [
    {src: require('../../../assets/img/gala11/gala11_1.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_2.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_3.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_4.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_5.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_6.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_7.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_8.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_9.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_10.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_11.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_12.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_13.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_14.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_15.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_16.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_17.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_18.JPG'), alt: "imagen_gala11"},
    {src: require('../../../assets/img/gala11/gala11_19.JPG'), alt: "imagen_gala11"},
];

export default {
    gala1: imagenesGala1,
    gala2: imagenesGala2,
    gala3: imagenesGala3,
    gala4: imagenesGala4,
    gala5: imagenesGala5,
    gala6: imagenesGala6,
    gala7: imagenesGala7,
    gala8: imagenesGala8,
    gala9: imagenesGala9,
    gala10: imagenesGala10,
    gala11: imagenesGala11,
};