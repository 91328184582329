import React, { useState, useEffect } from 'react';
import silueto from '../../../assets/img/nominats/silueto.png';
import guanyador from '../../../assets/img/guanyador.png';
import guanyadora from '../../../assets/img/guanyadora.png';

const Premiats = ({persona}) => {

  const [hovered, setHovered] = useState(false);
  const [loaded, setLoaded] = useState(false); 

  useEffect(() => {
    // Establecer un tiempo de espera antes de marcar la imagen como cargada
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 200); // Puedes ajustar el tiempo según tus necesidades

    // Limpiar el timeout al desmontar el componente para evitar memory leaks
    return () => clearTimeout(timeout);
  }, []);  

  return (
    <React.Fragment>    
      <div className={`relative w-full px-4 flex flex-col items-center justify-center sm:w-full md:w-80 cursor-pointer transition duration-300 ease-in-out ${hovered ? 'scale-110 z-50' : 'z-10'} ${loaded ? 'opacity-100 transition-opacity duration-1000' : 'opacity-0'}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className='z-0 w-10/12'>
            <img src={persona.src ? persona.src : silueto} alt={persona.alt} className={`w-full h-auto object-contain ${!persona.src ? 'invert' : ''} `}/>
        </div>
        {
          persona.nom !== 'Girona FC' ? (
            <div className='bg-white w-full rounded text-customBlue shadow-xl flex flex-col justify-between p-4 -mt-7 z-10 h-56 relative'>
              {/* Condición para mostrar la imagen de "guanyador" o "guanyadora" */}
              {persona.guanyador === 'Si' && (
                <img
                  src={persona.sexe === 'F' ? guanyadora : guanyador}
                  alt="Guanyador/a"
                  className="absolute top-0 right-0 w-full z-10 opacity-70"
                />
              )}
              <div className='flex flex-row justify-between z-20'>
                <div>
                  <h2 className='font-bold text-xl'>{persona.nom}</h2>
                  <p className='text-gray-500 font-semibold'>{persona.equip}</p>
                  <p className='text-gray-500 text-xs font-semibold'>{persona.naixement}</p>
                </div>
                {persona.escut && <img src={persona.escut} alt="escut" className={`w-10 ${persona.escut.includes('ESPANYOL') ? 'h-14' : 'h-10'}`}/>}
              </div>
              <div>
                { persona.categoria &&
                  (persona.sexe === 'F' ? <p className='font-bold z-30' translate="no">Candidata a:</p> : <p className='font-bold' translate="no">Candidat a:</p>)
                }
                <p className='text-gold font-bold' translate="no">{persona.categoria}</p>
              </div>
              {
                (persona.total) && 
                <div className='mt-2'>
                  <div className="bg-blue-50 h-6 rounded-full w-full relative">
                    <span
                      className="h-full bg-footerBlue w-full block rounded-full"
                      style={{width: persona.total}}
                    ></span>
                    <span className='text-customBlue absolute left-1/2 transform -translate-x-1/2 top-0 font-bold'>{persona.total.toString()}</span>
                  </div>
                </div>
              }
              
            </div>
          ) :
          (
            <div className='bg-white w-full rounded text-customBlue shadow-xl flex flex-col justify-between p-4 z-10 relative'>
              <p >{persona.descripcio}</p>
            </div>
          )
        }
        
      </div>  
    </React.Fragment>
  );
}

export default Premiats;
