import React, { useState, useEffect } from 'react';

const Imagen = ({ image, index, handleOnClicked }) => {

  const [hovered, setHovered] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Establecer un tiempo de espera antes de marcar la imagen como cargada
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 200); // Puedes ajustar el tiempo según tus necesidades

    // Limpiar el timeout al desmontar el componente para evitar memory leaks
    return () => clearTimeout(timeout);
  }, []);

  return (
    <React.Fragment>
      <div className={`sm:w-full w-10/12 cursor-pointer rounded overflow-hidden ${loaded ? 'opacity-100 transition-opacity duration-1000' : 'opacity-0'}`}>
      <div className={`sm:h-60 w-full overflow-hidden transition duration-300 ease-in-out ${hovered ? 'translate-y-1 scale-110' : ''}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => handleOnClicked(image.src, index)}
        >
          <img
            {...image}
            width={320}
            height={200}
            priority
            className="h-full w-full object-cover object-center"
            loading="lazy"
            alt="Imatge gala"
            //onLoad={() => setLoaded(true)} // Añadir clase para mostrar la imagen una vez cargada
          />
        </div>
      </div>
    </React.Fragment>
  );

  
}

export default Imagen;