import React from 'react';
import { Link } from 'react-router-dom';

const Xapa = ({ premi }) => {

  const cargarImagen = require.context("../../../assets/img/xapes/", true);

  return (
    <React.Fragment>
      <Link to={{ pathname: `/premis/${premi[0]}`}}>
        <div className='m-2'>       
          <div className='transform transition-transform hover:scale-125'>   
            <img id={premi[0]} src={cargarImagen(`./${premi[1]}.png`)} alt={premi[0]} className='w-40 h-40 min-w-40 min-h-40'/>
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
}

export default Xapa;
