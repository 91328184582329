import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import ErrorBoundary from './components/errorBoundary';
import Nav from './components/nav';
import Footer from './components/footer';
import Premis from './views/premis/premis';
import Noticies from './views/noticies/noticies';
import Jurat from './views/jurat/jurat';
import Gala from './views/gala/gala';
import Bases from './views/bases/bases';
import Fotos from './views/fotos/fotos';
import Galeria from './views/fotos/galeria';
import Premi from './views/premis/premi';

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <div className='app-container'>
          <Nav/>
          <div className='content'>
            <Routes>
              <Route path="/" element={<Premis/>}/>
              <Route path="/noticies" element={<Noticies/>}/>
              <Route path="/jurat" element={<Jurat/>}/>
              <Route path="/gala" element={<Gala/>}/>
              <Route path="/bases" element={<Bases/>}/>
              <Route path="/fotos" element={<Fotos/>}/>
              <Route path="/fotos/gala/:id" element={<Galeria/>}/>
              <Route path="/premis/:slug" element={<Premi/>}/>
            </Routes>
            </div>
          <Footer/>
        </div>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
