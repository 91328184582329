import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaAngleRight } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

const ModalComponent = ({
  images,
  selectedIndex,
  titulo,
  handleCloseModal,
}) => {

  const [localSelectedIndex, setLocalSelectedIndex] = useState(selectedIndex);
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    setLocalSelectedIndex(selectedIndex);
    document.body.classList.add("overflow-hidden");
    // Eliminar la clase al cerrar el modal
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [selectedIndex]);

  const handleNext = (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (localSelectedIndex !== undefined) {
      const nextIndex = localSelectedIndex === images.length - 1 ? 0 : localSelectedIndex + 1;
      setAnimationClass("animate-slide-left-out");
      setTimeout(() => {
        setLocalSelectedIndex(nextIndex);
        setAnimationClass("animate-slide-left-in");
      }, 200);
    }
  };

  const handlePrev = (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (localSelectedIndex !== undefined) {
      const prevIndex = localSelectedIndex === 0 ? images.length - 1 : localSelectedIndex - 1;
      setAnimationClass("animate-slide-right-out");
      setTimeout(() => {
        setLocalSelectedIndex(prevIndex);
        setAnimationClass("animate-slide-right-in");
      }, 200);
    }
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (startX - endX > 50) {
      handleNext();
    } else if (endX - startX > 50) {
      handlePrev();
    }
  };

  const handleTouchMove = (e) => {
    setEndX(e.touches[0].clientX);
  };

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = (e) => {
    setEndX(e.clientX);
    window.removeEventListener("mouseup", handleMouseUp);
    handleTouchEnd();
  };
  
  const selectedImage = images && images[localSelectedIndex] && images[localSelectedIndex].src;

  return (
      <div className={`fixed inset-0 flex flex-col justify-center items-center z-50 max-h-screen-2/4 w-full bg-black transition-opacity overflow-hidden ${selectedImage ? 'bg-opacity-70 transition-opacity duration-1000' : 'opacity-0'}`}
      onClick={handleCloseModal}
      >
        <button
          className="absolute top-0 right-0 m-2 md:m-4 text-3xl md:text-4xl text-white font-bold opacity-100 sm:opacity-80 hover:opacity-100"
          onClick={handleCloseModal}
        >
          <IoClose />
        </button>
        <button
          className="z-10 absolute top-1/2 transform -translate-y-1/2 left-0 text-white opacity-100 sm:opacity-80 py-4 px-2 md:py-24 md:px-4 text-xl md:text-4xl hover:opacity-100"
          onClick={handlePrev}
        >
          <FaChevronLeft />
        </button>
        <button
          className="z-10 absolute top-1/2 transform -translate-y-1/2 right-0 text-white opacity-100 sm:opacity-80 py-4 px-2 md:py-24 md:px-4 text-xl md:text-4xl hover:opacity-100"
          onClick={handleNext}
        >
          <FaAngleRight />
        </button>
        <div id="contenido" className="w-full-2/4 h-full-2/4 max-h-screen md:w-3/4 lg:w-2/3 xl:w-1/2 flex flex-col justify-center items-center">
          <div >
            <div className="w-full h-full px-2 md:px-4 pb-4">
              <div className={`relative h-full flex justify-center items-center ${animationClass}`}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              onTouchMove={handleTouchMove}
              onMouseDown={handleMouseDown}
              draggable
              >
                <img src={selectedImage} alt="Imatge seleccionada" className="max-h-screen object-contain"/>
              </div>  
            </div>
            {/*<div className="px-2 md:px-4 flex flex-col">
              <span className="text-white text-opacity-80 text-md md:text-xl font-bold">{titulo} </span>
              <span className="text-white text-opacity-80">{`Imatge ${localSelectedIndex + 1} de ${images.length}`}</span>
            </div>*/}
          </div>
        </div>
      </div>
  );
};

export default ModalComponent;
