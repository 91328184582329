import React, { useEffect } from "react";
import { IoClose } from "react-icons/io5";

const PopUp = ({selectedPersona, handleCloseModal}) => {

  // Evitar scroll en la pantalla debajo del popup
  useEffect(() => {
    // Solo añadir overflow-hidden cuando el modal esté abierto
    if (selectedPersona) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    // Limpiar al desmontar o cuando se cierra el modal
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [selectedPersona]);

  // Manejar clics fuera del contenido
  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };
  
  return selectedPersona && (
    <div className="fixed inset-0 flex justify-center items-center z-50 min-h-screen w-full bg-black bg-opacity-70 overflow-auto p-2"
    onClick={handleClickOutside}>
        <div className="relative overflow-auto bg-white max-h-screen rounded sm:w-3/5 md:w-4/6 lg:w-4/6 xl:w-3/5 w-full h-auto mt-10 mb-10 text-customBlue p-8 overflow-y-auto"
          onClick={(e) => e.stopPropagation()}
        >
            <button
              className="absolute top-0 right-0 m-4 text-2xl md:text-3xl text-customBlue font-bold opacity-100 hover:opacity-80 focus:outline-none"
              onClick={handleCloseModal}
            >
              <IoClose />
            </button>
            <div className="flex flex-col lg:flex-row items-center">
                <div className="sm:w-2/5 w-full sm:mb-0 mb-2">
                    <div className='shadow-bottom w-60 mt-8 sm:mt-10 sm:mb-4 mb-2 flex flex-row justify-between'>
                        <img src={selectedPersona.src} alt={selectedPersona.alt}/>
                        {selectedPersona.escut && <img src={selectedPersona.escut} alt="escut" className={`w-12 -ml-12 ${selectedPersona.escut.includes('ESPANYOL') ? 'h-16' : 'h-12'}`}/>}
                    </div>
                    <h2 className='font-bold text-xl'>{selectedPersona.nom}</h2>
                    <p className='text-gray-500 font-semibold'>{selectedPersona.equip}</p>
                    <p className='text-gray-500 text-xs font-semibold'>{selectedPersona.naixement}</p>
                    
                </div>
                <div className="sm:w-3/5 md:pl-4 md:w-full w-full text-justify mt-6">
                  {
                    selectedPersona.categoria &&
                    (selectedPersona.sexe === 'F' ? <p className='font-bold' translate="no">Candidata a:</p> : <p className='font-bold' translate="no">Candidat a:</p>)
                  }
                  <p className='text-gold font-bold mb-4' translate="no">{selectedPersona.categoria}</p>
                  <p translate="no">{selectedPersona.descripcio}</p>
                </div>
            </div>
        </div>
    </div>
    
  );
};

export default PopUp;
