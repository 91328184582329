import React, { useState, useEffect } from 'react';
import { NavLink as RRNavLink, useLocation } from 'react-router-dom';
import logo from '../assets/img/LogoGalaEstrella12.png';
import logoMovil from '../assets/img/Estrella.png';

const Nav = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

    const closeMenu = () => {
      setIsOpen(false);
    };

    const NavLink = ({ to, children, isOpen }) => {
      const location = useLocation();
      const isActive = location.pathname === to || (to === '/fotos' && location.pathname.startsWith('/fotos/gala/')) || (to === '/' && location.pathname.startsWith('/premis/'));
    
      let classNames = 'custom-link';
      if (isOpen && isActive) {
        classNames += ' text-white';
      } else if (!isOpen && isActive) {
        //Añadimos un tiempo de espera para que no cambie la clase antes de que se cierre el menú móvil
        //setTimeout(() => {
          classNames += ' custom-link-active';
      //}, 500);
      }
    
      return (
        <RRNavLink to={to} onClick={closeMenu} className={classNames}>
          {children}
        </RRNavLink>
      );
    }

    //Efecto para controlar el scroll down para modificar la NavBar
    useEffect(() => {
      const handleScroll = () => {
        // Verificar si hay suficiente contenido para hacer scroll
        const documentHeight = document.documentElement.scrollHeight;
        const windowHeight = window.innerHeight;
    
        if (documentHeight > windowHeight) {
          const scrollTop = window.scrollY;
          setIsScrolled(scrollTop > 0);
        }
      };
    
      window.addEventListener('scroll', handleScroll);
    
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    //Efecto para controlar el scroll to top cuando cambias de vista
    useEffect(() => {
      window.scrollTo(0, 0); 
    }, [location]);

    return (
        <React.Fragment>
            <nav className={`text-white flex justify-between items-center uppercase ${isScrolled ? 'bg-customBlue z-20 fixed top-0 left-0  w-full' : ''} transition-all duration-300 ease-in-out`}>
              {/* Navegación horizontal */}
              <div className="w-3/5 mx-auto">
              <div className={`hidden lg:flex items-center justify-center w-full text-xs ${isScrolled ? 'space-x-16' : 'space-x-10'}`}>
                <NavLink to="/" isOpen={isOpen} className="px-4 custom-link">Premis</NavLink>
                <NavLink to="/noticies" isOpen={isOpen} className="custom-link">Notícies</NavLink>
                <NavLink to="/jurat" isOpen={isOpen} className="custom-link">Jurat</NavLink>
                <a href="/" >
                <img
                            src={isScrolled ? logoMovil : logo}
                            alt='Logo'
                            className={`${isScrolled ? 'h-10 w-10 min-w-10' : 'h-20 w-52 min-w-52'} transition-all duration-300 ease-in-out`}
                        />
                </a>
                <NavLink to="/gala" isOpen={isOpen} className="custom-link">La Gala</NavLink>
                <NavLink to="/bases" isOpen={isOpen} className="custom-link">Bases</NavLink>
                <NavLink to="/fotos" isOpen={isOpen} className="custom-link">Fotos</NavLink>
              </div>
              </div>
              {/* Botón de menú para dispositivos móviles */}
              <div className={`fixed top-0 left-0 h-10 lg:hidden w-screen z-20`}>
                <div className='bg-customBlue flex justify-between'>
                <button onClick={toggleMenu} className="ml-7 my-2 h-10 relative focus:outline-none ">
                  <div className="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <span aria-hidden="true" className={`block h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${isOpen ? 'rotate-45 translate-y-1.5' : ''}`}/>
                    <span aria-hidden="true" className={`block h-0.5 w-5 bg-current transform transition duration-500 ease-in-out mt-1 ${isOpen ? 'opacity-0' : ''}`}/>
                    <span aria-hidden="true" className={`block h-0.5 w-5 bg-current transform transition duration-500 ease-in-out mt-1 ${isOpen ? '-rotate-45 -translate-y-1.5' : ''}`}/>
                  </div>
                </button>
                <a href='/'>
                  <img src={logoMovil} alt='Logo' className='w-8 h-8 right-0 my-2 mr-2'/>
                </a>
                </div>
              </div>
              {/**Menú desplegable para dispositivos móviles */}
              <div id="menu-mvl" className={`lg:hidden fixed flex flex-col top-0 left-0 p-2 mt-12 w-2/3 h-full z-20 bg-customBlue text-gray-400 transform transition-all duration-500 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                <div id="nav" className='flex flex-col ml-10 mt-2 space-y-2'>
                  <NavLink to="/" isOpen={isOpen} className="py-2">Premis</NavLink>
                  <NavLink to="/noticies" isOpen={isOpen} className="py-2">Notícies</NavLink>
                  <NavLink to="/jurat" isOpen={isOpen} className="py-2">Jurat</NavLink>
                  <NavLink to="/gala" isOpen={isOpen} className="py-2">La Gala</NavLink>
                  <NavLink to="/bases" isOpen={isOpen} className="py-2">Bases</NavLink>
                  <NavLink to="/fotos" isOpen={isOpen} className="py-2">Fotos</NavLink>
                </div>
              </div>
            </nav>
        </React.Fragment>
    );
}

export default Nav;